<script setup lang="ts">
import {type ModuleConfiguration} from "@/vf"
import {ref} from "vue"

const props = withDefaults(
    defineProps<{
        config: ModuleConfiguration
        invert?: boolean
    }>(),
    {invert: false},
)

function onChange(event: Event) {
    const target = event.target as HTMLSelectElement
    props.config.list.requestParams.value = {
        ...(props.config.list.requestParams.value ?? {}),
        includeOpen: ['open', 'all'].includes(target.value) ? "1" : "0",
        includeDone: ['done', 'all'].includes(target.value) ? "1" : "0",
    }
}
</script>

<template>
    <div class="form-control-with-icon d-inline-flex ml-4">
        <i class="fas fa-check"></i>
        <select
            class="form-control"
            @change="onChange"
        >
            <option value="open">{{ $t("@tasks:issue.filter_open_issues_button") }}</option>
            <option value="done">{{ $t("@tasks:issue.filter_done_issues_button") }}</option>
            <option value="all" selected>{{ $t("@tasks:issue.filter_all_issues_button") }}</option>
        </select>
    </div>
</template>
