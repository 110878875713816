<script setup lang="ts">
import RotateChevron from "@/components/RotateChevron.vue"
import { useToggleableValue } from "@/vf"
import { type FaqGroup, type FaqPage } from "./FaqTypes"

const props = defineProps<{
    group: FaqGroup
    selectedPage: FaqPage | null
    open?: boolean
}>()

const emit = defineEmits<{
    (event: "page-selected", page: FaqPage): void
}>()

const [openChildGroup, setOpenChildGroup] = useToggleableValue()

if (props.open) {
    setOpenChildGroup(props.group)
}
</script>

<template>
    <div
        class="accordion-enumeration-menu-item"
        :class="{ active: group === openChildGroup }"
        @click="setOpenChildGroup(group)"
    >
        <RotateChevron :open="group === openChildGroup" />
        {{ group.name }}
    </div>

    <div class="ml-3" v-animate-show="group === openChildGroup">
        <CmsFaqSidebarGroup
            v-for="childGroup in group.children"
            :key="childGroup.id"
            :group="childGroup"
            :selected-page="selectedPage"
            @page-selected="emit('page-selected', $event)"
        />
        <div
            v-for="page in group.pages"
            class="accordion-enumeration-menu-item"
            :class="{ active: selectedPage === page }"
            @click="emit('page-selected', page)"
        >
            {{ page.name }}
        </div>
    </div>
</template>
