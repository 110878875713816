import type { OrganizationMember } from "@/model/app/organization-member"
import { h } from "vue"
import { useI18n } from "vue-i18n"

export function renderConnectionStatus(member: OrganizationMember, t: ReturnType<typeof useI18n>["t"]) {
    const r = []
    if (member.managerUserId) {
        r.push(
            renderConnectionStatusItem(
                member.managerStatus,
                t("@app:ManagementStatus." + member.managerStatus),
                member.managerUserId,
                member.managerUser?.firstName,
                member.managerUser?.lastName,
                member.managerUser?.organizations,
                t("@app:organization_member.managementTypes.manager"),
            ),
        )
    }
    if (member.connectionUserId) {
        r.push(
            renderConnectionStatusItem(
                member.connectionStatus,
                t("@app:ManagementStatus." + member.connectionStatus),
                member.connectionUserId,
                member.connectedUser?.firstName,
                member.connectedUser?.lastName,
                member.connectedUser?.organizations,
                t("@app:organization_member.managementTypes.myself"),
            ),
        )
    }
    r.push(
        h(
            "div",
            {
                class: "mb-1",
            },
            [
                h("i", {
                    class: "fa fa-fw fa-building-user",
                }),
                " " +
                    (member.organization && member.organization.type === "Organization"
                        ? member.organization.name
                        : t("@app:organization_member.organization.not_associated")),
            ],
        ),
    )

    return r
}

function renderConnectionStatusItem(
    status: string | undefined,
    statusTitle: string,
    userId: string,
    firstName: string,
    lastName: string,
    organizations: string[],
    label: string,
) {
    return h(
        "div",
        {
            class: "mb-1",
        },
        [
            h("i", {
                class: "fas fa-fw fa-circle management-status-" + status,
                title: statusTitle,
            }),
            label + ": " + userId + (firstName || lastName ? " (" + [firstName, lastName].join(" ") + ")" : ""),
        ],
    )
}
