<script setup lang="ts">
import type { Project } from "@/model/tasks/project"
import { useHttpClient, type ModuleConfiguration } from "@/vf"

const props = defineProps<{
    config: ModuleConfiguration
}>()

const http = useHttpClient()

const projects = (await http.get<Project[]>("tasks/issue/projects", props.config.list.requestParams.value)).data

function onChange(event: Event) {
    const target = event.target as HTMLSelectElement
    props.config.list.requestParams.value = {
        ...(props.config.list.requestParams.value ?? {}),
        project: target.value,
    }
}
</script>

<template>
    <div class="form-control-with-icon d-inline-flex ml-4" v-if="projects.length > 0">
        <i class="fas fa-calendar-day"></i>
        <select class="form-control" @change="onChange">
            <option v-for="project of projects" :value="project.id">
                {{ project.name }}
            </option>
            <option value="" selected>{{ $t("@tasks:issue.filter_all_projects_button") }}</option>
        </select>
    </div>
</template>
