<script lang="ts" setup>
import CheckboxToggle from "@/components/form/CheckboxToggle.vue"
import QuillEditor from "@/components/quill/QuillEditor.vue"
import { type TextTemplate } from "@/model/communication/text-template"
import { useHttpClient, type Entity } from "@/vf"
import { reactive, ref } from "vue"
import ResponseAttachments from "./ResponseAttachments.vue"

const props = defineProps<{
    issue: Entity
    viewMode?: "admin" | "customer"
}>()

const emit = defineEmits<{
    (e: "responded"): void
    (e: "close"): void
}>()

const http = useHttpClient()
const selectedTextTemplate = ref<TextTemplate>(null)

const data = reactive({
    text: "",
    attachments: [] as File[],
    internal: false,
    subject:
        props.issue.tasks[0]?.taskType == "CommunicationMessage" && props.issue.tasks[0]?.nameProps["name"]
            ? props.issue.tasks[0].nameProps["name"]
            : null,
})

const textTemplates =
    props.viewMode === "admin"
        ? (await http.get<TextTemplate[]>(`/communication/text-template/for-issue/${props.issue.id}`)).data
        : []

function insertTextTemplate() {
    data.text = data.text + selectedTextTemplate.value.text
}

let submitting = false

async function save() {
    if (data.text.trim() == "" && data.attachments.length == 0) {
        return
    }
    if (submitting) {
        return
    }
    submitting = true

    const formData = new FormData()
    formData.set("text", data.text)
    if (data.subject) {
        formData.set("taskName", (!data.subject.startsWith("Re: ") ? "Re: " : "") + data.subject)
    }

    if (data.internal) {
        formData.set("internal", "1")
    }

    for (const attachment of data.attachments) {
        formData.append("attachments[]", attachment)
    }

    await http.post(`/communication-messages/${props.issue.id}?office=${props.viewMode === "admin" ? 1 : 0}`, formData)
    emit("responded")
    submitting = false
}
</script>

<template>
    <form @submit.prevent="save" class="card-body" action="javascript:void(0)">
        <QuillEditor class="quill-light" v-model="data.text"></QuillEditor>
        <ResponseAttachments v-model="data.attachments" class="mt-3"></ResponseAttachments>

        <div class="d-flex justify-content-between mt-4" v-if="viewMode === 'admin'">
            <div>
                <CheckboxToggle v-model="data.internal">{{ $t("@communication:internal_message") }}</CheckboxToggle>
            </div>
            <div class="d-flex" v-if="textTemplates.length > 0">
                <div class="mt-2 mr-2">{{ $t("@communication:select_template") }}</div>
                <select class="form-control w-auto" v-model="selectedTextTemplate">
                    <option v-for="template in textTemplates" :value="template">
                        {{ template.name }}
                    </option>
                </select>
                <button class="btn btn-secondary ml-5" type="button" @click="insertTextTemplate()">
                    {{ $t("@communication:insert_template") }}
                </button>
            </div>
        </div>

        <div class="d-flex justify-content-between mt-5">
            <div>
                <button type="button" class="btn btn-primary" @click="emit('close')">
                    {{ $t("@tasks:issue.issueRespond.cancel") }}
                </button>
            </div>
            <div>
                <button type="submit" class="btn btn-primary">
                    {{ $t("@tasks:issue.issueRespond.submit") }}
                </button>
            </div>
        </div>
    </form>

    <!-- <VfForm
        api-base="communication-messages"
        schema-url="communication-messages/respond-schema"
        :create-url="'communication-messages/' + props.issue.id + '?office=' + (viewMode === 'admin' ? '1' : '0')"
        @saved="emit('responded')"
    >
        <VfFormRest></VfFormRest>

        <button type="submit" class="btn btn-primary">Antworten</button>
    </VfForm> -->
</template>
